import React from 'react'
import { Flex, Box, Text } from '@chakra-ui/react'
import { IProductCollection } from '@common/interface/product'
import Image from 'next/image'
import { useBrandDetailCollection } from '@lib/context/brand-detail-collection-context'
import useFilterQueryParams from '@common/hooks/use-filter-query-param'
import { convertToCloudFrontImage } from '@lib/util/convert-cloudfront-image'

interface PropsType {
  collection: IProductCollection
  color?: string
  textColor?: string
}

const CollectionCartItem: React.FC<PropsType> = ({
  collection,
  color,
  textColor = 'black'
}) => {
  const { setCollection } = useBrandDetailCollection()
  const { addCollection } = useFilterQueryParams()

  const handleClick = () => {
    // setCollection({
    //   ids: [collection.id],
    //   type: 'collection',
    //   title: collection.title
    // })
    addCollection(collection.handle)
  }

  return (
    <Flex
      onClick={handleClick}
      cursor="pointer"
      flexDirection="column"
      minW="100%"
      h="100%"
    >
      <Box position={'relative'} maxWidth={'100%'} height={'auto'} flex={1}>
        <Image
          src={convertToCloudFrontImage(collection.image ?? '')}
          alt={collection.title}
          width={360}
          height={435}
          quality={100}
          style={{
            width: '100%',
            maxHeight: '435px'
          }}
          priority={true}
          sizes="(max-width: 479px) 100vw, (max-width: 767px) 50vw, (max-width: 1279px) 33vw, 25vw"
        />
      </Box>
      <Box
        style={{
          background: color ?? 'black',
          color: 'white',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '0px',
          minHeight: '120px'
        }}
        p={{
          md: '24px 60px',
          base: '12px 24px'
        }}
      >
        <Text
          style={{
            marginTop: '0px !important'
          }}
          textStyle="sectionHeaderText"
          color={'white'}
        >
          {collection.title}
        </Text>
      </Box>
    </Flex>
  )
}

export default CollectionCartItem
