import {
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
  useState
} from 'react'

export type CollectionInBrandItemType = 'brand' | 'collection'
export interface ICollectionState {
  title: string
  ids: string[]
  type: CollectionInBrandItemType
}

interface BrandDetailCollectionContext {
  collection: ICollectionState | null
  setCollection: Dispatch<SetStateAction<ICollectionState | null>>
}

export const BrandDetailCollectionContext =
  createContext<BrandDetailCollectionContext | null>(null)

export const BrandDetailCollectionProvider = ({
  children
}: {
  children: React.ReactNode
}) => {
  const [collection, setCollection] = useState<ICollectionState | null>(null)

  return (
    <BrandDetailCollectionContext.Provider
      value={{ collection, setCollection }}
    >
      {children}
    </BrandDetailCollectionContext.Provider>
  )
}

export const useBrandDetailCollection = () => {
  const context = useContext(BrandDetailCollectionContext)

  if (context === null) {
    throw new Error(
      'useBrandDetailCollection must be used within a BrandDetailCollectionProvider'
    )
  }

  return context
}
