import React from 'react'
import Image from 'next/image'
import { Box, Text, Stack, Grid, GridItem } from '@chakra-ui/react'
import LinkTextUnderline from '@components/link-text-underline'
import {
  ICollectionState,
  useBrandDetailCollection
} from '@lib/context/brand-detail-collection-context'
import { COLLECTION_ALL_IN_BRAND } from '@constants/brand.constant'
import BrandParser from './brand-parser'
import useFilterQueryParams from '@common/hooks/use-filter-query-param'
import { convertToCloudFrontImage } from '@lib/util/convert-cloudfront-image'

const BrandDetailBanner = ({ color, content, allCollectionIds }: any) => {
  const { setCollection } = useBrandDetailCollection()
  const { addAll } = useFilterQueryParams()

  return (
    <Box>
      <Grid templateColumns="repeat(12, 1fr)">
        <GridItem
          data-aos="fade-up"
          colSpan={{
            lg: 5,
            base: 12
          }}
          py={16}
          alignItems="center"
          display="flex"
          bg={color}
        >
          <Grid templateColumns="repeat(12, 1fr)" w="100%">
            <GridItem
              colStart={{
                lg: 2,
                base: 1
              }}
              colEnd={{
                lg: 12,
                base: 13
              }}
              px={4}
            >
              <Stack
                gap={{
                  md: 8,
                  base: 4
                }}
              >
                <Text textStyle="sectionHeader" color="white">
                  {content.detail.title}
                </Text>
                <BrandParser
                  styles={{
                    color: 'white',
                    fontSize: '18px'
                  }}
                  content={content.detail.content}
                />
                <LinkTextUnderline
                  text={'Shop Now'}
                  onClick={() => addAll('true')}
                  color={'white'}
                  textStyle="smallTitle"
                ></LinkTextUnderline>
              </Stack>
            </GridItem>
          </Grid>
        </GridItem>

        <GridItem
          data-aos="fade-up"
          colSpan={{
            lg: 7,
            base: 12
          }}
          position="relative"
          minH="100%"
          // NOTE - IMAGE SIZE
          // 1050/750
          style={{
            aspectRatio: '1080/1080'
          }}
        >
          <Image
            src={convertToCloudFrontImage(content.detail.image)}
            alt={content.detail.title?.toLowerCase() ?? 'shop'}
            fill
            sizes="(max-width: 767px) 50vw, 100w"
          />
        </GridItem>
      </Grid>
    </Box>
  )
}

export default BrandDetailBanner
