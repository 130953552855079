import React, { Fragment } from 'react'
import {
  IUSPBrandContent,
  USPContent as USPContentType
} from '@common/interface/brand-detail.interface'
import { Box, Grid, GridItem, Text } from '@chakra-ui/react'
import Image from 'next/image'

interface USPContentProps {
  usp_content: IUSPBrandContent
}

const SubContentUSP = ({ contents }: { contents: USPContentType[] }) => {
  return (
    <Grid
      templateColumns="repeat(12, 4fr)"
      gap={{ base: 6, lg: 4 }}
      maxW={'1080px'}
    >
      {contents.map((content, k) => {
        return (
          <GridItem
            data-aos="fade-up"
            key={k}
            colSpan={{ lg: 4, base: 6 }}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            gap={5}
          >
            <Image
              src={content.image}
              width={64}
              height={64}
              alt={content.title}
            />
            <Text textAlign={'center'} textStyle={'uspTitleSub'}>
              {content.title}
            </Text>
            <Text textAlign={'center'} textStyle="uspDescription">
              {content.description}
            </Text>
          </GridItem>
        )
      })}
    </Grid>
  )
}

const ContentUSP = ({ contents }: { contents: USPContentType[] }) => {
  return (
    <Grid
      templateColumns="repeat(12, 1fr)"
      gap={{ base: '20px', lg: '98px' }}
      maxW={'1124px'}
      mt={10}
    >
      {contents.map((content, k) => {
        if (k % 2 === 0) {
          return (
            <Fragment key={k}>
              <GridItem
                data-aos="fade-up"
                colSpan={{ lg: 6, base: 12 }}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                gap={5}
              >
                <Image
                  src={content.image}
                  width={602}
                  height={602}
                  alt={content.title}
                />
              </GridItem>
              <GridItem
                data-aos="fade-up"
                key={k}
                colSpan={{ lg: 6, base: 12 }}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                gap={5}
              >
                <Text textStyle={'uspTitle'}>{content.title}</Text>
                <Text textStyle={'uspTitleSub'}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: (content.description as string) ?? ''
                    }}
                  ></div>
                </Text>
              </GridItem>
            </Fragment>
          )
        } else {
          return (
            <Fragment key={k}>
              <GridItem
                key={k}
                colSpan={{ lg: 6, base: 12 }}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                gap={5}
              >
                <Text textStyle={'uspTitle'}>{content.title}</Text>
                <Text textStyle={'uspTitleSub'}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: (content.description as string) ?? ''
                    }}
                  ></div>
                </Text>
              </GridItem>
              <GridItem
                key={k}
                colSpan={{ lg: 6, base: 12 }}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                gap={5}
              >
                <Image
                  src={content.image}
                  width={602}
                  height={602}
                  alt={content.title}
                />
              </GridItem>
            </Fragment>
          )
        }
      })}
    </Grid>
  )
}

const USPContent: React.FC<USPContentProps> = ({ usp_content }) => {
  const { title, description, sub_contents, usp_contents } = usp_content

  return (
    <Box>
      <Grid templateColumns="repeat(12, 1fr)">
        <GridItem
          colSpan={{
            lg: 12,
            base: 12
          }}
          py={16}
          alignItems="center"
          display="flex"
          justifyContent={'center'}
          flexDirection={'column'}
          gap={{ base: '20px', lg: '80px' }}
          px={{ base: 2, lg: 0 }}
        >
          <Text
            data-aos="fade-up"
            textStyle={'sectionHeader'}
            textAlign={'center'}
            maxW={'1080px'}
          >
            {title}
          </Text>
          <Text
            data-aos="fade-up"
            textStyle="uspDescription"
            textAlign={'center'}
            maxW={'1080px'}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: (description as string) ?? ''
              }}
            ></div>
          </Text>
          <SubContentUSP contents={sub_contents} />
          <ContentUSP contents={usp_contents} />
        </GridItem>
      </Grid>
    </Box>
  )
}

export default USPContent
