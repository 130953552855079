export const convertUTCDateToLocalDate = (date: Date) => {
  const newDate = new Date(date.getTime() + date.getTimezoneOffset())

  const offset = date.getTimezoneOffset() / 60
  const hours = date.getHours()

  newDate.setHours(hours - offset)

  return newDate
}

export const expiredDate = (date: Date) => {
  return (
    new Date(date).getTime() - convertUTCDateToLocalDate(new Date()).getTime() <
    0
  )
}

export const startDate = (date: Date) => {
  return (
    new Date(date).getTime() -
      convertUTCDateToLocalDate(new Date()).getTime() <=
    0
  )
}
