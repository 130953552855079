import { useEffect } from 'react'
import AOS from 'aos'

const useAosAnimation = ({
  duration = 600,
  once = false
}: {
  duration?: number
  once?: boolean
}) => {
  useEffect(() => {
    // github.com/michalsnik/aos/issues/547#issuecomment-628110095
    setTimeout(() => {
      AOS.init({
        duration: duration,
        once: once
      })
    }, 1000)
  }, [once, duration])
}

export default useAosAnimation
