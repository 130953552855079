import {
  Button,
  Flex,
  Box,
  Text,
  HStack,
  Container,
  SimpleGrid
} from '@chakra-ui/react'
import React from 'react'
import { useResponsive } from '@common/hooks'
import BodySubLayout from '@layouts/body-sub-layout'
import { MdKeyboardArrowLeft } from '@icons/index'
import { useFeaturedProductsQuery } from '@lib/hooks/use-layout-data'
import ListProducts from '@components/product/list-product'
import useFilterProducts from '@common/hooks/use-filter-product'
import useFilterQueryParams from '@common/hooks/use-filter-query-param'
import { FilterQueryParams } from '@constants/filter.constant'
import { useCollection } from 'medusa-react'
import ListProductLoading from '@components/product/list-product-loading'
import { PRODUCT_LIMIT } from '@constants/product.constant'

const BrandDetailCollectionProducts = ({
  collectionIds,
  bg
}: {
  collectionIds: string[]
  bg: string
}) => {
  const { data, isLoading, isError } = useFeaturedProductsQuery({
    collection_id: collectionIds,
    limit: 2000
  })

  const { smMax } = useResponsive()

  if (isError) return null

  if (isLoading) {
    return (
      <Container>
        <SimpleGrid
          columns={PRODUCT_LIMIT}
          spacingX={{ base: 5, lg: '40px' }}
          spacingY={smMax ? 12 : 16}
          justifyContent={'center'}
        >
          <ListProductLoading />
        </SimpleGrid>
      </Container>
    )
  }

  if (!isLoading && !data?.products?.length)
    return (
      <Text textStyle="ourStoryTextMedium" fontStyle="italic">
        There is no products here
      </Text>
    )

  return (
    <>
      {collectionIds.length > 0 && (
        <ListProducts
          items={data?.products ?? []}
          isLoading={isLoading}
          color="white"
          bg={bg}
        />
      )}
    </>
  )
}

const BrandDetailShopping = ({ bg }: { bg: string }) => {
  const { xlMax } = useResponsive()

  const { filter } = useFilterProducts()
  const { removeQuery } = useFilterQueryParams()

  const { collection } = useCollection(filter?.collection_id[0] ?? '')

  return (
    <Box className="products">
      <BodySubLayout>
        <Box data-aos="fade-up" alignItems="center" justifyContent="center">
          <Text textStyle="sectionHeader" color="black" textAlign={'center'}>
            {collection?.title ?? ''}
          </Text>
        </Box>
        <Button
          data-aos="fade-up"
          variant="unstyled"
          leftIcon={
            <Box mt={-1}>
              <MdKeyboardArrowLeft width={6} />
            </Box>
          }
          alignItems="center"
          display="flex"
          onClick={() => {
            removeQuery(FilterQueryParams.COLLECTIONS)
          }}
        >
          Back
        </Button>
        <Flex
          data-aos="fade-up"
          alignItems={'center'}
          justifyContent={'center'}
          flexDirection={'column'}
          mt={xlMax ? 8 : 10}
        >
          <BrandDetailCollectionProducts
            collectionIds={filter?.collection_id ?? []}
            bg={bg}
          />
        </Flex>
      </BodySubLayout>
    </Box>
  )
}

export default BrandDetailShopping
