import React from 'react'
import BrandDetail from '@components/brand-detail'
import Head from '@modules/common/components/head'
import { useRouter } from 'next/router'
import { useQuery } from '@tanstack/react-query'
import { getBrand } from '@lib/util/brand-handler'
import BrandLayout from '@layouts/brand-layout'
import logo1 from '@images/brands/brand-detail-logo-1.png'
import bannerLogo1 from '@images/brands/brand-detail-banner-logo-1.png'
import banner1 from '@images/brands/brand-detail-banner-1.png'
import subBanner1 from '@images/brands/brand-detail-sub-banner-1.png'
import trend1 from '@images/brands/brand-detail-trend-1.png'
import trend11 from '@images/brands/brand-detail-trend-11.png'
import trend12 from '@images/brands/brand-detail-trend-12.png'
import logo2 from '@images/brands/brand-detail-logo-2.png'
import bannerLogo2 from '@images/brands/brand-detail-banner-logo-2.png'
import banner2 from '@images/brands/brand-detail-banner-2.png'
import subBanner2 from '@images/brands/brand-detail-sub-banner-2.png'
import trend2 from '@images/brands/brand-detail-trend-2.png'
import trend21 from '@images/brands/brand-detail-trend-21.png'
import trend22 from '@images/brands/brand-detail-trend-22.png'
import logo3 from '@images/brands/brand-detail-logo-3.png'
import bannerLogo3 from '@images/brands/brand-detail-banner-logo-3.png'
import banner3 from '@images/brands/brand-detail-banner-3.png'
import subBanner3 from '@images/brands/brand-detail-sub-banner-3.png'
import trend3 from '@images/brands/brand-detail-trend-3.png'
import trend31 from '@images/brands/brand-detail-trend-31.png'
import trend32 from '@images/brands/brand-detail-trend-32.png'
import logo4 from '@images/brands/brand-detail-logo-4.png'
import bannerLogo4 from '@images/brands/brand-detail-banner-logo-4.png'
import banner4 from '@images/brands/brand-detail-banner-4.png'
import subBanner4 from '@images/brands/brand-detail-sub-banner-4.png'
import trend4 from '@images/brands/brand-detail-trend-4.png'
import trend41 from '@images/brands/brand-detail-trend-41.png'
import trend42 from '@images/brands/brand-detail-trend-42.png'
import { BrandDetailCollectionProvider } from '@lib/context/brand-detail-collection-context'
import useAosAnimation from '@common/hooks/use-aos-animation'

export const brandDetailMockDataByHandle = {
  Arctic: {
    logo: logo1,
    banner_logo: bannerLogo1,
    banner_image: banner1,
    content: {
      title: 'HAPPY SIPS OF LIFE',
      content: `<p>Enjoy life in sips. At just the right temperature. The Arctic brand is about being temperature perfect for you. After all, we've been in the same situation as you, frustrated with hot drinks turning cold. Or cold drinks losing their chillness. And what about the condensation from your cold drink, dripping on all over? The Arctic brand is specially curated to get you out of those irksome situations. Hottest-looking tumblers, flasks, mugs, jars and cups to suit every lifestyle for any occasion.</p>
      
      <b>Sip Life. Enjoy its grandeur.</b>`,
      image: subBanner1
    },
    trendings: {
      trending_1: {
        title: 'Lorem Deals.',
        content:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod .',
        collection_id: 'pcol_01GZWR31315G7NQ1H45X202M1R',
        image: trend1
      },
      trending_2: {
        title: 'Lorem Deals.',
        collection_id: 'pcol_01GZWR31315G7NQ1H45X202M1R',
        image: trend11
      },
      trending_3: {
        title: 'Lorem Deals.',
        collection_id: 'pcol_01GZWR31315G7NQ1H45X202M1R',
        image: trend12
      }
    }
  },
  'gourmet-chef': {
    logo: logo2, // Replace `logo2` with the actual imported image for the "gourmet-chef" handle
    banner_logo: bannerLogo2, // Replace `bannerLogo2` with the actual imported image for the "gourmet-chef" handle
    banner_image: banner2, // Replace `banner2` with the actual imported image for the "gourmet-chef" handle
    content: {
      title: 'GOURMET CHEF',
      content: `<p>Experience culinary delights like never before. The Gourmet Chef brand brings you the finest ingredients, expertly crafted recipes, and innovative kitchen tools to elevate your cooking game. Discover the joy of creating exquisite dishes that tantalize your taste buds and impress your guests.</p>
      
      <b>Unleash your inner chef. Indulge in culinary mastery.</b>`,
      image: subBanner2 // Replace `subBanner2` with the actual imported image for the "gourmet-chef" handle
    },
    trendings: {
      trending_1: {
        title: 'Special Recipes.',
        content:
          'Discover our collection of special recipes from renowned chefs.',
        collection_id: 'pcol_01GZWR31315G7NQ1H45X202M1R',
        image: trend2
      },
      trending_2: {
        title: 'Cookware Essentials.',
        content:
          'Explore our premium selection of cookware essentials for professional-grade cooking.',
        collection_id: 'pcol_01GZWR31315G7NQ1H45X202M1R',
        image: trend21
      },
      trending_3: {
        title: 'Gourmet Tools.',
        content:
          'Enhance your culinary experience with our range of gourmet tools and accessories.',
        collection_id: 'pcol_01GZWR31315G7NQ1H45X202M1R',
        image: trend22
      }
    }
  },
  health: {
    logo: logo3,
    banner_logo: bannerLogo3,
    banner_image: banner3,
    content: {
      title: 'HEALTH & WELLNESS',
      content: `<p>Prioritize your health and well-being with our range of products designed to support a balanced lifestyle. From nutritious supplements to fitness equipment, we provide everything you need to embark on your wellness journey.</p>
      
      <b>Nurture your body. Embrace a healthier you.</b>`,
      image: subBanner3
    },
    trendings: {
      trending_1: {
        title: 'Holistic Nutrition.',
        content:
          'Discover our selection of nutrient-rich and wholesome food options.',
        collection_id: 'pcol_01GZWR31315G7NQ1H45X202M1R',
        image: trend3
      },
      trending_2: {
        title: 'Fitness Gear.',
        content:
          'Equip yourself with top-quality fitness gear and accessories.',
        collection_id: 'pcol_01GZWR31315G7NQ1H45X202M1R',
        image: trend31
      },
      trending_3: {
        title: 'Wellness Essentials.',
        content:
          'Explore our range of wellness products to enhance your self-care routine.',
        collection_id: 'pcol_01GZWR31315G7NQ1H45X202M1R',
        image: trend32
      }
    }
  },
  ultimo: {
    logo: logo4,
    banner_logo: bannerLogo4,
    banner_image: banner4,
    content: {
      title: 'NEW ARRIVALS',
      content: `<p>Stay ahead of the latest trends with our new arrivals. Discover innovative products, stylish designs, and cutting-edge technology that redefine your everyday experiences. Be the first to experience the next big thing.</p>
      
      <b>Embrace the new. Embrace the extraordinary.</b>`,
      image: subBanner4
    },
    trendings: {
      trending_1: {
        title: 'Innovative Gadgets.',
        content:
          'Explore our collection of innovative gadgets and tech accessories.',
        collection_id: 'pcol_01GZWR31315G7NQ1H45X202M1R',
        image: trend4
      },
      trending_2: {
        title: 'Fashion Must-Haves.',
        content:
          'Stay fashionable with our curated selection of trendy clothing and accessories.',
        collection_id: 'pcol_01GZWR31315G7NQ1H45X202M1R',
        image: trend41
      },
      trending_3: {
        title: 'Home Essentials.',
        content:
          'Discover essential home products and decor to transform your living space.',
        collection_id: 'pcol_01GZWR31315G7NQ1H45X202M1R',
        image: trend42
      }
    }
  }
}

const BrandDetailPage = () => {
  const { query } = useRouter()

  const { data } = useQuery(['brand', query.handle], () =>
    getBrand((query.handle as string) ?? '')
  )

  useAosAnimation({
    duration: 600,
    once: false
  })

  if (!data) {
    return null
  }

  const { handle, navbar_logo, productSection, content, usp_content } = data

  const current =
    brandDetailMockDataByHandle[
      handle as keyof typeof brandDetailMockDataByHandle
    ]

  const customData = {
    ...data,
    navbar_logo: navbar_logo || current?.logo,
    content: {
      // footer is not have default value
      ...content,
      header: {
        image: content?.header.image || current?.banner_image,
        image_logo: content?.header.image_logo || current?.banner_logo,
        slogan: content?.header.slogan
      },
      detail: {
        title: content?.detail.title || current?.content.title,
        image: content?.detail.image || current?.content.image,
        content: content?.detail.content || current?.content.content
      },
      trending: content?.trending || current?.trendings
    },
    usp_content
  }

  return (
    <BrandDetailCollectionProvider>
      <Head
        title={customData?.title}
        description={data?.metadata?.meta_description}
      />
      <BrandLayout
        name={customData?.title}
        color={customData?.color ?? undefined}
        brand={customData}
        productSection={productSection}
      >
        {customData && (
          <BrandDetail brand={customData} productSection={productSection} />
        )}
      </BrandLayout>
    </BrandDetailCollectionProvider>
  )
}

export default BrandDetailPage
