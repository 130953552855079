import {
  Box,
  Flex,
  Grid,
  GridItem,
  Text,
  useBreakpointValue
} from '@chakra-ui/react'
import { useResponsive } from '@common/hooks'
import BodySubLayout from '@layouts/body-sub-layout'
import Image from 'next/image'
import LinkTextUnderline from '@components/link-text-underline'
import { useBrandDetailCollection } from '@lib/context/brand-detail-collection-context'
import BrandParser from './brand-parser'
import { useCollection } from 'medusa-react'
import useFilterProducts from '@common/hooks/use-filter-product'
import useFilterQueryParams from '@common/hooks/use-filter-query-param'
import { useRouter } from 'next/router'
import { convertToCloudFrontImage } from '@lib/util/convert-cloudfront-image'

const Item = ({
  collectionId,
  image,
  title,
  content,
  link,
  isLarge,
  contentProps,
  color = 'black',
  imageProps,
  ...rest
}: any) => {
  const { setCollection } = useBrandDetailCollection()
  const { collection } = useCollection(collectionId)
  const { addCollection } = useFilterQueryParams()
  const router = useRouter()

  const fontSize = useBreakpointValue({
    base: '9px',
    md: '16px',
    lg: '20px'
  })

  const onClickHandler = () => {
    if (link != null) {
      router.replace(link)
    } else {
      if (collection != null) {
        addCollection(collection.handle)
      }
    }
  }

  return (
    <Box
      position="relative"
      cursor="pointer"
      {...rest}
      onClick={onClickHandler}
    >
      <Image
        src={image}
        fill
        alt={`${title as string} trending`}
        {...imageProps}
      />

      <Box position="absolute" zIndex={2} maxW="400px" px={4} {...contentProps}>
        <Text
          textStyle={isLarge ? 'brandTrandingTitle' : 'brandTrandingSubtitle'}
          color={color}
          maxW={{ base: 32, sm: 40, md: 72, lg: 96 }}
        >
          {title}
        </Text>
        {content ? (
          <Box maxW={{ base: 32, sm: 40, md: 72, lg: 96 }} mt={1}>
            <BrandParser
              content={content}
              styles={{
                color,
                fontSize
              }}
            />
          </Box>
        ) : null}

        <Box mt={{ lg: 8, md: 4, base: 2 }}>
          <LinkTextUnderline
            text="Shop Now"
            href={link}
            color={color}
            textStyle="smallTitle"
            target={'_blank'}
            onClick={() => {
              setCollection({
                title,
                type: 'collection',
                ids: [collectionId]
              })
            }}
            textProps={{
              fontSize: {
                base: '10px',
                md: '18px',
                lg: '22px'
              },
              lineHeight: {
                base: '14px',
                md: '24px',
                lg: '26px'
              },
              py: {
                lg: 2,
                base: 0
              }
            }}
          ></LinkTextUnderline>
        </Box>
      </Box>
    </Box>
  )
}

const BrandDetailTrending = ({ content }: any) => {
  const { xlMax } = useResponsive()

  const checkRedirectUrl = (url?: string) => {
    if (typeof url === 'string') {
      try {
        const newUrl = new URL(url)
        if (newUrl.protocol === 'http:' || newUrl.protocol === 'https:') {
          return url
        }
        return undefined
      } catch (error) {
        return undefined
      }
    }
    return undefined
  }

  return (
    <Box>
      <BodySubLayout>
        <Text
          data-aos="fade-up"
          textStyle="sectionHeader"
          color="black"
          mb={xlMax ? 8 : 10}
          textAlign={'center'}
        >
          Trending
        </Text>

        <Grid
          templateColumns="repeat(12, 1fr)"
          gap={{
            md: 12,
            base: 4
          }}
        >
          {content.trending?.trending_1 ? (
            <GridItem data-aos="fade-up" colSpan={12}>
              <Item
                image={convertToCloudFrontImage(
                  content.trending.trending_1.image
                )}
                title={content.trending.trending_1.title}
                content={content.trending.trending_1.content}
                link={checkRedirectUrl(
                  content.trending.trending_1.redirect_url
                )}
                collectionId={content.trending.trending_1.collection_id}
                color={content.trending.trending_1.text_color}
                isLarge
                contentProps={{
                  left: {
                    xl: '150px',
                    lg: '120px',
                    md: '20px',
                    sm: '20px',
                    base: 0
                  },
                  top: '50%',
                  transform: 'translateY(-50%)'
                }}
                style={{
                  aspectRatio: '1640 / 620'
                }}
                minH="180px"
                imageProps={{
                  sizes: '100vw'
                }}
              />
            </GridItem>
          ) : null}

          {content.trending?.trending_2 ? (
            <GridItem
              data-aos="fade-up"
              colSpan={{
                md: 6,
                base: 12
              }}
            >
              <Item
                image={convertToCloudFrontImage(
                  content.trending.trending_2.image
                )}
                title={content.trending.trending_2.title}
                link={checkRedirectUrl(
                  content.trending.trending_2.redirect_url
                )}
                collectionId={content.trending.trending_2.collection_id}
                color={content.trending.trending_2.text_color}
                contentProps={{
                  right: '40px',
                  top: '20%'
                }}
                style={{
                  aspectRatio: '788 / 620'
                }}
                imageProps={{
                  sizes: '(max-width: 767px) 100vw, 50vw'
                }}
              />
            </GridItem>
          ) : null}

          {content.trending?.trending_3 ? (
            <GridItem
              data-aos="fade-up"
              colSpan={{
                md: 6,
                base: 12
              }}
            >
              <Item
                image={convertToCloudFrontImage(
                  content.trending.trending_3.image
                )}
                title={content.trending.trending_3.title}
                link={checkRedirectUrl(
                  content.trending.trending_3.redirect_url
                )}
                collectionId={content.trending.trending_3.collection_id}
                color={content.trending.trending_3.text_color}
                contentProps={{
                  right: '40px',
                  top: '20%'
                }}
                style={{
                  aspectRatio: '788 / 620'
                }}
                imageProps={{
                  sizes: '(max-width: 767px) 100vw, 50vw'
                }}
              />
            </GridItem>
          ) : null}
        </Grid>
      </BodySubLayout>
    </Box>
  )
}

export default BrandDetailTrending
