import React from 'react'
import parse from 'html-react-parser'

// Custom font and font-weight style

const BrandParser = ({
  content,
  styles
}: {
  content: any | undefined
  styles: any
}) => {
  const customStyle = {
    fontFamily: 'DM Sans Regular',
    fontWeight: 400,
    backgroundColor: 'transparent',
    ...styles
  }

  if (!content) return null

  // Parse HTML with custom style
  const parsedHtml = parse(content, {
    replace: (domNode: any) => {
      if (domNode.type === 'text') {
        // Apply custom style to text nodes
        return <div style={customStyle}>{domNode?.data}</div>
      }

      return undefined // Return undefined to keep the original domNode
    }
  })

  return <div>{parsedHtml}</div>
}

export default BrandParser
