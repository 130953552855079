import React from 'react'
import { IProductCollection } from '@common/interface/product'
import CollectionCartItem from './collection-cart-item'
import ListProductLoading from '@components/product/list-product-loading'
import PaginationLayout from '@layouts/pagination-layout'
import { ITEMS_PER_PAGE } from '@constants/product.constant'

interface PropsType {
  collections: IProductCollection[]
  isLoading?: boolean
  color?: string
}

const ListCollections: React.FC<PropsType> = ({
  collections,
  isLoading,
  color
}) => {
  if (isLoading) return <ListProductLoading />

  if (!collections || !collections?.length) return null

  const filteredCollections = collections.filter(
    (item) => !item.metadata?.isBrand
  )

  return (
    <PaginationLayout
      data={filteredCollections}
      itemsPerPage={{
        xl: 4,
        lg: 3,
        md: 3,
        sm: 2,
        base: 1
      }}
      renderItem={(item: any) => {
        return (
          <CollectionCartItem
            collection={item}
            color={color}
            textColor="white"
          />
        )
      }}
    />
  )
}

export default ListCollections
