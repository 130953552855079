import { Box, Flex, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import BackgroundHeader from './background-header'
import ListProductByCollection from '@components/product/list-product-by-collection'
import { useResponsive } from '@common/hooks'
import ListCollections from '@components/collection/list-collections'
import BodySubLayout from '@layouts/body-sub-layout'
import BrandDetailVisit from './brand-detail-visit'
import BrandDetailTrending from './brand-detail-trending'
import BrandDetailBanner from './brand-detail-banner'
import { IBrandDetail } from '@common/interface/brand-detail.interface'
import BrandDetailShopping from './brand-detail-shopping'
import { scroller } from 'react-scroll'
import useFilterQueryParams from '@common/hooks/use-filter-query-param'
import { useRouter } from 'next/router'
import USPContent from './usp_content'

interface PropsType {
  brand: IBrandDetail
  productSection: any
}

const BrandDetail = ({ brand, productSection }: PropsType) => {
  const { xlMax } = useResponsive()

  const { collections, all } = useFilterQueryParams()

  const router = useRouter()

  useEffect(() => {
    if (collections != null || all != null) {
      scroller.scrollTo('products', {
        duration: 500,
        smooth: true,
        spy: true,
        offset: 50
      })
    }
  }, [collections, all, router?.query])

  const allCollectionIds = brand.collections.map((item) => item.id)

  return (
    <Box>
      <BackgroundHeader {...brand} allCollectionIds={allCollectionIds} />

      {!collections ? (
        <>
          <BrandDetailBanner {...brand} allCollectionIds={allCollectionIds} />
          {brand.usp_content && <USPContent usp_content={brand.usp_content} />}

          <BrandDetailTrending {...brand} />

          {brand?.collections?.length > 0 && (
            <BodySubLayout>
              <Text
                data-aos="fade-up"
                textStyle="sectionHeader"
                color="black"
                mb={xlMax ? 8 : 10}
                textAlign={'center'}
              >
                Collections
              </Text>
              <Flex
                data-aos="fade-up"
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}
              >
                <ListCollections
                  collections={brand?.collections ?? []}
                  color={brand?.color}
                />
              </Flex>
            </BodySubLayout>
          )}

          <Box className="products">
            <BodySubLayout>
              <Text
                data-aos="fade-up"
                textStyle="sectionHeader"
                color="black"
                mb={xlMax ? 8 : 10}
                textAlign={'center'}
                ref={productSection}
              >
                Products
              </Text>
              <Box data-aos="fade-up">
                <ListProductByCollection
                  collections={brand?.collections?.map((c: any) => c.id) ?? []}
                  totalProducts={
                    brand?.collections?.reduce((pre: number, c: any) => {
                      return (pre += c.products?.length || 0)
                    }, 0) || 0
                  }
                  data={brand}
                  bg={brand?.color ?? 'black'}
                  color="white"
                />
              </Box>
            </BodySubLayout>
          </Box>
        </>
      ) : (
        <BrandDetailShopping bg={brand?.color ?? 'black'} />
      )}

      <BrandDetailVisit {...brand} />
    </Box>
  )
}

export default BrandDetail
