import React from 'react'
import Footer from '@components/footer'
import { Box } from '@chakra-ui/react'
import MainContainer from '@components/main-container'
import Navbar from '@components/navbar'
import { NAV_ITEMS_BRAND_LAYOUT } from '@constants/navbar.constant'
import Image from 'next/image'
import RouterUrl from '@constants/router.constant'
import RecaptchaProvider from '@components/footer/recaptcha-provider'

interface BrandLayoutPropsType {
  children: React.ReactNode
  name?: string
  color?: string
  brand?: any
  productSection: any
}

const BrandLayout = ({
  children,
  name,
  color,
  brand,
  productSection
}: BrandLayoutPropsType) => {
  return (
    <Box>
      <Navbar
        name={name}
        color={color}
        productSection={productSection}
        navItems={NAV_ITEMS_BRAND_LAYOUT}
        brand={brand}
        logo={
          <Image
            src={brand?.navbar_logo}
            alt={brand?.name ?? 'brand logo'}
            width={200}
            height={40}
            layout="responsive"
          />
        }
        onRedirect={() => {
          if (typeof window !== 'undefined') {
            window.location.href = `${RouterUrl.BRAND.ROOT}/${
              brand.handle as string
            }`
          }
        }}
      />
      <MainContainer
        display={'flex'}
        flexDirection={'column'}
        mt={{
          base: '78px',
          xl: '96px'
        }}
      >
        {children}
      </MainContainer>
      <RecaptchaProvider>
        <Footer
          color={color != null ? 'white' : undefined}
          bg={color}
          subscribeColor={'black'}
        />
      </RecaptchaProvider>
    </Box>
  )
}

export default BrandLayout
