import React from 'react'
import Image from 'next/image'
import { Box, Text, Stack, Grid, GridItem } from '@chakra-ui/react'
import image from '@images/brands/brand-detail-footer-1.png'
import MainContainer from '@components/main-container'
import Link from 'next/dist/client/link'
import BrandParser from './brand-parser'

const BrandDertailVisit = ({ color, navbar_logo, content }: any) => {
  return (
    <MainContainer data-aos="fade-up">
      <Grid templateColumns="repeat(12, 1fr)">
        <GridItem
          colSpan={{
            lg: 4,
            base: 12
          }}
          alignItems="center"
          display="flex"
          bg={color}
          py={16}
        >
          <Grid templateColumns="repeat(12, 1fr)" w="100%">
            <GridItem
              colStart={{
                lg: 2,
                base: 1
              }}
              colEnd={{
                lg: 12,
                base: 13
              }}
              px={4}
            >
              <Stack
                gap={{
                  lg: 8,
                  base: 4
                }}
                w="100%"
              >
                <Box
                  maxW={{
                    lg: '100%',
                    base: '80%'
                  }}
                >
                  <Image
                    src={content.footer?.image_logo || navbar_logo}
                    width={300}
                    height={120}
                    alt=""
                  />
                </Box>

                <Text
                  textStyle="sectionHeader"
                  color="white"
                  pt={{
                    lg: 8,
                    base: 4
                  }}
                >
                  {content.footer?.title || ''}
                </Text>

                <BrandParser
                  content={content.footer?.content}
                  styles={{
                    fontSize: '18px',
                    color: 'white'
                  }}
                />

                <Link
                  href={content.footer?.link_visitus || ''}
                  target="_blank"
                  style={{
                    maxWidth: '70%'
                  }}
                >
                  <Image
                    src={content.footer?.image_visitus}
                    width={434}
                    height={78}
                    alt="brand-detail-visit-button"
                  />
                </Link>
              </Stack>
            </GridItem>
          </Grid>
        </GridItem>

        <GridItem
          colSpan={{
            lg: 8,
            base: 12
          }}
          bg={color}
          position="relative"
          minH="100%"
          // NOTE - IMAGE SIZE
          // 1260/632
          style={{
            aspectRatio: '1260/632'
          }}
        >
          <Image
            src={content?.footer?.image || image}
            alt={content.footer?.title ?? 'A Brand by Habbit'}
            fill
            sizes="(max-width: 991px) 100w, 65w"
          />
        </GridItem>
      </Grid>
    </MainContainer>
  )
}

export default BrandDertailVisit
