import { Box, Text } from '@chakra-ui/react'
import dynamic from 'next/dynamic'
import { useResponsive } from '@common/hooks'
import useFilterQueryParams from '@common/hooks/use-filter-query-param'
import LinkTextUnderline from '@components/link-text-underline'
import { useBrandDetailCollection } from '@lib/context/brand-detail-collection-context'
import { convertToCloudFrontImage } from '@lib/util/convert-cloudfront-image'
import Image from 'next/image'
import React from 'react'
import { IconPropsType } from '@icons/icon-props'

const LogoIcon = dynamic<IconPropsType>(
  () => import('@icons/logo-icon').then((mod) => mod.LogoIcon),
  {
    ssr: false
  }
)

interface PropsType {
  banner_image: string
  title: string
  color?: string
  banner_logo?: string
  id: string
}

const BackgroundHeader = ({
  content,
  color,
  id,
  allCollectionIds
}: PropsType & any) => {
  const { setCollection } = useBrandDetailCollection()
  const { addAll } = useFilterQueryParams()

  const { lgMax } = useResponsive()

  return (
    <Box data-aos="fade-up" position={'relative'}>
      {/* NOTE - IMAGE SIZE */}
      {/* 1800/800 */}
      <Box
        style={{
          aspectRatio: '1920/957'
        }}
        minH="400px"
      >
        <Image
          src={convertToCloudFrontImage(content.header.image ?? '')}
          alt={content.header.slogan || 'brand title'}
          quality={100}
          style={{ width: '100%', objectFit: 'cover' }}
          fill
          priority
          sizes="100vw"
        />
      </Box>

      <Box>
        <Box
          width="100%"
          top="50%"
          left="50%"
          position={'absolute'}
          justifyContent={'center'}
          alignItems={'center'}
          transform="translateX(-50%) translateY(-50%)"
          px={4}
        >
          <Box
            maxW={{
              lg: '600px',
              md: '300px',
              sm: '200px',
              base: '150px'
            }}
            maxH={{
              lg: '200px',
              base: '100px'
            }}
            mx="auto"
          >
            <Image
              src={convertToCloudFrontImage(content.header.image_logo ?? '')}
              width={400}
              height={120}
              layout="responsive"
              alt=""
            />
          </Box>

          <Box
            textAlign="center"
            mt={{
              lg: 12,
              base: 8
            }}
          >
            <Text textStyle="sectionHeader">{content.header.slogan}</Text>

            <Box
              mt={{
                lg: 4,
                base: 2
              }}
            >
              <LinkTextUnderline
                text="Shop Now"
                color="black"
                onClick={() => addAll('true')}
                textProps={{
                  fontSize: {
                    lg: 22,
                    md: 14,
                    base: 12
                  },
                  py: 0
                }}
              />
            </Box>
          </Box>
        </Box>

        <Box
          position="absolute"
          bottom={{
            lg: '5%',
            base: 4
          }}
          left="50%"
          transform="translateX(-50%)"
        >
          <LogoIcon
            width={lgMax ? 40 : 80}
            height={lgMax ? 30 : 61}
            color={color ?? 'black'}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default BackgroundHeader
