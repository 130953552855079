import React, { useEffect } from 'react'
import { SimpleGrid } from '@chakra-ui/react'
import ProductCardItem from './product-card-item'
import { IProductItem } from '@common/interface/product'
import ListProductLoading from './list-product-loading'
import { useResponsive } from '@common/hooks'
import useGA4DataLayer from '@common/hooks/use-ga4-data-layer'

interface ListProductPropsType {
  items: IProductItem[]
  columns?: any
  isLoading?: boolean
  bg?: string
  color?: string
}

const ListProduct = ({
  items,
  columns,
  isLoading,
  bg,
  color
}: ListProductPropsType) => {
  const { smMax } = useResponsive()

  const { viewListItemTag } = useGA4DataLayer()

  useEffect(() => {
    if (items.length > 0) {
      viewListItemTag(items)
    }
  }, [items])

  const displayItems = () => {
    if (items.length > 0) {
      return items.map((item, index) => {
        return (
          <ProductCardItem
            item={item}
            key={`${item.id}-${index}`}
            bg={bg}
            color={color}
          />
        )
      })
    }
  }

  return (
    <SimpleGrid
      columns={{ base: 2, sm: 2, md: 3, lg: 4, xl: 4 }}
      spacingX={{ base: 5, lg: '40px' }}
      spacingY={smMax ? 12 : 16}
      justifyContent={'center'}
    >
      {!isLoading && displayItems()}
      {isLoading && <ListProductLoading />}
    </SimpleGrid>
  )
}

export default ListProduct
